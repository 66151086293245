import {RetryLink} from "@apollo/client/link/retry";

const retryLink = new RetryLink({
    delay: {
        initial: 2000,
        max: 10000,
        jitter: true
    },
    attempts: {
        max: 3,
        retryIf: (error:any, _operation:any) => !!error
    }
});

export default retryLink;