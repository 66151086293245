import {TokenRefreshLink} from "apollo-link-token-refresh";
import {
    deleteAccessToken,
    deleteExpiresIn,
    deleteRefreshToken,
    getAccessToken, getExpiresIn, getRefreshToken,
    setAccessToken,
    setExpiresIn
} from "../tokenUtils";
import jwt_decode from "jwt-decode";
import axios from "axios";

const getNewToken = (): Promise<any> => {

    return axios.post("/rest/auth/refresh", {refreshToken: getRefreshToken()})
}

const isTokenExpired = (): boolean => {
    const tokenExpiresIn = getExpiresIn();
    const currentTime = new Date().getTime();


    if (tokenExpiresIn < currentTime) {
        return true;
    }

    return (tokenExpiresIn - currentTime) <= 10 * 1000;
};


const jwtLink = new TokenRefreshLink({
    isTokenValidOrUndefined: () =>  !isTokenExpired() || typeof getAccessToken() !== 'string',
    fetchAccessToken: getNewToken,
    handleFetch: (accessToken:any) => {
        console.info("jwtLink handleFetch");
        const accessTokenDecrypted = jwt_decode<any>(accessToken);
        setAccessToken(accessToken);
        setExpiresIn(accessTokenDecrypted.exp);
    },
    handleResponse: (operation:any, accessTokenField:any) => (response:any) => {
        console.info("jwtLink handleResponse");
        return { access_token: response.data.accessToken }
    },
    handleError: (err:any) => {
        deleteAccessToken()
        deleteRefreshToken()
        deleteExpiresIn()
        window.location.replace("/")
        window.location.reload()
    }
})

export default jwtLink