import {setContext} from "@apollo/client/link/context";
import {getAccessToken} from "../tokenUtils";

const authLink = setContext((_: any, {headers}: any) => {
    console.info('authLink authToken ' + getAccessToken())
    return {
        headers: {
            ...headers,
            authorization: "Bearer " + getAccessToken(),
        },
    };
});

export default authLink;