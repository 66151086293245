import {UserInfo} from "./authContext";

let userInfo:UserInfo;

export const setRefreshToken = (token:string) => localStorage.setItem("refreshToken", token);

export const setAccessToken = (token:string):void => localStorage.setItem("accessToken", token)

export const getAccessToken = ():string | null => localStorage.getItem("accessToken");

export const setUserInfo = (info:UserInfo):void => { userInfo = info; };

export const setExpiresIn = (seconds:number):void => localStorage.setItem("tokenExpiresIn", seconds.toString());

export const getExpiresIn = ():number => Number.parseInt(localStorage.getItem("tokenExpiresIn") || "0") * 1000;

export const deleteExpiresIn = ():void => localStorage.removeItem("tokenExpiresIn");

export const getRefreshToken = ():string | null => localStorage.getItem("refreshToken");

export const deleteAccessToken = ():void => localStorage.removeItem("accessToken");

export const deleteRefreshToken = ():void => localStorage.removeItem("refreshToken");