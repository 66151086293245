import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/react-hooks";
import {PrinterLink} from "apollo-link-printer";
import jwtLink from "./links/jwtLink";
import authLink from "./links/authLink";
import retryLink from "./links/retryLink";

export const getApolloClient = (): ApolloClient<any> => new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([jwtLink, retryLink, authLink, httpLink]),
        queryDeduplication: true,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only"
            }
        }
    })

const printer = new PrinterLink({
    print: true,
    collapsed: false
})

const httpLink = createHttpLink({
    uri: "/graphql"
})
