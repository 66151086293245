import React, {useContext, useEffect, useState} from 'react';
import {
    deleteAccessToken,
    deleteRefreshToken,
    getAccessToken,
    setAccessToken,
    setExpiresIn,
    setRefreshToken,
    getRefreshToken, setUserInfo,
} from './tokenUtils';
import jwt_decode from 'jwt-decode';
import axios from "axios";
import {Role, RoleCode} from "../model/roles";

type AuthContextData = ReturnType<typeof useProvideAuth>;

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }:any) => {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export interface AuthResponse {
    accessToken: string
    refreshToken: string
    messages: string[] | undefined
}

export interface UserInfo {
    id: string
    lastName: string
    name: string
    surName: string
    login: string
    description: string
    iconUrl: string
    roles: Role[]
    canAuth?: Boolean
}

export interface JwtTokenData {
    sub: string
    userInfo: UserInfo
    exp: number
}

const userInfoInitial:UserInfo = {id: "", name: "", lastName:"", login: "", surName: "",iconUrl: "", description: "", roles: []}

const useProvideAuth = () => {
    // const [isLoading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState<UserInfo>(userInfoInitial)
    const isAuthenticated = () => getAccessToken() !== null;

    const login = async (username: string, password: string) => {
        const response = await axios.post<SignInResponse>('/rest/auth', {
            login: username,
            password,
        });
        const { accessToken, refreshToken } = response.data;
        const decodedAccessToken = jwt_decode<JwtTokenData>(accessToken);

        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setExpiresIn(decodedAccessToken.exp);
        setUserInfo(decodedAccessToken.userInfo);
    };

    const logout = async () => {
        const refreshToken = await getRefreshToken();
        const accessToken = await getAccessToken();
        await axios.post('/rest/auth/logout', {
            refreshToken, accessToken
        });
        deleteAccessToken();
        deleteRefreshToken();
        document.location.replace("/login")
    };

    const updateUserInfo = (token: string) => {
        const decodedAccessToken = jwt_decode<JwtTokenData>(token);
        setUserInfo(decodedAccessToken.userInfo)
    }

    const refreshToken = async () => {
        await getNewToken().then((response:any) => {
            setAccessToken(response.data.accessToken);
            updateUserInfo(response.data.accessToken)
        });
    };

    useEffect(() => {
        const initAuth = async () => {
            const storedToken = await getAccessToken();
            const storedRefreshToken = await getRefreshToken();

            if (storedToken == null && storedRefreshToken !== null) {
                await refreshToken();
            }
        };

        const updateUserInfo = async () => {
            const storedToken = await getAccessToken();

            if (storedToken !== null) {
                const { userInfo } = jwt_decode<JwtTokenData>(storedToken)
                setUserInfo(userInfo);
            }
        }

        initAuth().then(updateUserInfo);
    }, []);
    return {
        isAdministrator: () => userInfo.roles.map(role => role.code).includes(RoleCode.ADMINISTRATOR),
        isAuthenticated: isAuthenticated(),
        login,
        logout,
        token: () => getAccessToken(),
        userInfo
    };
};

export function getNewToken() {
    return axios.post("/rest/auth/refresh", getRefreshToken())
}

interface SignInResponse {
    accessToken:string
    refreshToken: string
}

export default AuthContext